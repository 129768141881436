// eslint-disable-next-line import/extensions
import { CrossWindowClient } from '@openticket/lib-shop/lib/messaging';
import axios from 'axios';
import { NotificationController } from '@openticket/vue-notifications';
import { Localization } from '@openticket/lib-localization';
import type { StyleTheme } from '../utils/style';
import Style from '../utils/style';
import type {
    CartCallback,
    OrderTotalCallback,
    OrderTotalRawCallback,
    PartialCartData,
    WidgetStatusCallback,
    WidgetToggledCallback,
} from '../widget_types';
import { WidgetStatus } from '../widget_types';
import { ShopWidgetDom } from './ShopWidgetDom';
import type { ShopWidgetConfig } from '../types';
import { toBoolean } from '../utils/toBoolean';

export class ShopWidget {

    public initialized!: Promise<void>;

    public opened = false;

    public shopURL!: string;

    public shopId!: string;

    public client!: CrossWindowClient;

    private _resolveInitialized!: () => void;

    private _widgetToggledListener?: WidgetToggledCallback;

    private _widgetStatusListener?: WidgetStatusCallback;

    private _cartTicketsUpdatedListener?: CartCallback;

    private _orderTotalUpdatedListener?: OrderTotalCallback;

    private _rawOrderTotalUpdatedListener?: OrderTotalRawCallback;

    private _lastCartData?: string;

    private _fullSizePopup: boolean = false;

    private _dom: ShopWidgetDom;

    private _localization: Localization;

    private _config: ShopWidgetConfig | null;

    private _widgetStatus: {
        status: typeof WidgetStatus[keyof typeof WidgetStatus],
        numberOfTickets?: number,
        totalPriceCents?: number,
        previousStatus: string
    } = {
            status: WidgetStatus.Loading,
            previousStatus: '',
        };

    constructor() {
        this.initialized = new Promise((resolve) => {
            this._resolveInitialized = resolve;
        });
        this._localization = new Localization();
        this._dom = new ShopWidgetDom();
        this._config = null;
    }

    public async init(
        shopURL: string,
        shopId: string,
        config?: ShopWidgetConfig,
    ): Promise<void> {
        this.shopURL = shopURL;
        this.shopId = shopId;

        this._config = this.initConfigValues(config);

        document.body.classList.add('ot-vars');

        const listeners = this._dom.mount(
            config && config.targetElement ? config.targetElement : document.body,
            config && config.buttonless ? config.buttonless : false,
        );

        this._widgetStatus.status = WidgetStatus.Loading;
        this.updateWidgetStatus();

        await Promise.all([
            this._localization.init(),
            this.initShopSettings(),
        ]);

        await listeners.loadComplete(this.shopURL, (window) => this.initClient(window));

        Style.initColorSchemeListener();

        const _notifications = new NotificationController({
            position: 'topCenter',
            singleNotification: true,
        });
        this.client.onNotification((data) => {
            _notifications.show(data);
        });

        await this._localization.setLocale(this.client.localization.locale);
        this.client.onLocale((data: { locale: string }) => {
            // Todo: if CrossWindowClient gets merged into this repo, allow the callbacks to receive promises
            //  (and remove this terrible iife pattern)
            void (async () => {
                try {
                    await this._localization.setLocale(data.locale);
                    this._dom.updateLocale(this._localization, data.locale, this.client.localization.currency);
                } catch (err: unknown) {
                    console.error(err);
                }
            })();
        });
        this._dom.updateLocale(this._localization, this.client.localization.locale, this.client.localization.currency);

        listeners.onWidgetOpen = () => {
            if (this._widgetStatus.status === WidgetStatus.Closed) {
                this.opened = true;

                this._widgetStatus.status = WidgetStatus.Opened;
                this.updateWidgetStatus();

                if (this._widgetToggledListener) {
                    this._widgetToggledListener(true);
                }
            }
        };

        listeners.onWidgetClose = () => {
            if (this._widgetStatus.status === WidgetStatus.Opened) {
                this.opened = false;

                this._widgetStatus.status = WidgetStatus.Closed;
                this.updateWidgetStatus();

                if (this._widgetToggledListener) {
                    this._widgetToggledListener(false);
                }
            }
        };

        listeners.onResize = () => {
            this._dom.resize(this._fullSizePopup);
        };

        this.client.onSeats((data: { state: 'created' | 'mounted' | 'destroy' | 'validation' }) => {
            switch (data.state) {
                case 'created':
                case 'mounted':
                case 'validation':
                    this._fullSizePopup = true;
                    break;
                default:
                    this._fullSizePopup = false;
                    break;
            }
            this._dom.resize(this._fullSizePopup);
        });

        this._widgetStatus.status = WidgetStatus.Closed;
        this._widgetStatus.numberOfTickets = 0;
        this._widgetStatus.totalPriceCents = 0;
        this.updateWidgetStatus();

        return Promise.resolve();
    }

    private initConfigValues(suppliedConfig?: ShopWidgetConfig): ShopWidgetConfig | null {
        if (!suppliedConfig) {
            return null;
        }
        const resultingConfig: ShopWidgetConfig = {};

        if (
            suppliedConfig
            && suppliedConfig.cookiePreferences
            && toBoolean(suppliedConfig.cookiePreferences.iHaveAlreadyAskedTheCookiePreferencesAndTakeResponsibility)
        ) {
            resultingConfig.cookiePreferences = {
                iHaveAlreadyAskedTheCookiePreferencesAndTakeResponsibility: true,
            };

            if (toBoolean(suppliedConfig.cookiePreferences.analytical, null) !== null) {
                resultingConfig.cookiePreferences.analytical = toBoolean(suppliedConfig.cookiePreferences.analytical);
            }

            if (toBoolean(suppliedConfig.cookiePreferences.embeddedContent, null) !== null) {
                resultingConfig.cookiePreferences.embeddedContent = toBoolean(suppliedConfig.cookiePreferences.embeddedContent);
            }

            if (toBoolean(suppliedConfig.cookiePreferences.functional, null) !== null) {
                resultingConfig.cookiePreferences.functional = toBoolean(suppliedConfig.cookiePreferences.functional);
            }

            if (toBoolean(suppliedConfig.cookiePreferences.organiserMarketing, null) !== null) {
                resultingConfig.cookiePreferences.organiserMarketing = toBoolean(
                    suppliedConfig.cookiePreferences.organiserMarketing,
                );
            }
        }

        if (suppliedConfig && toBoolean(suppliedConfig.strict, null) !== null) {
            resultingConfig.strict = toBoolean(suppliedConfig.strict);
        }

        resultingConfig.trackingInfo = {};
        if (suppliedConfig && suppliedConfig.trackingInfo) {
            if (suppliedConfig.trackingInfo.google_client_id && typeof suppliedConfig.trackingInfo.google_client_id === 'string') {
                resultingConfig.trackingInfo.google_client_id = suppliedConfig.trackingInfo.google_client_id;
            }
            if (suppliedConfig.trackingInfo.click_id && typeof suppliedConfig.trackingInfo.click_id === 'string') {
                resultingConfig.trackingInfo.click_id = suppliedConfig.trackingInfo.click_id;
            }
            if (suppliedConfig.trackingInfo.click_id_source && typeof suppliedConfig.trackingInfo.click_id_source === 'string') {
                resultingConfig.trackingInfo.click_id_source = suppliedConfig.trackingInfo.click_id_source;
            }
            if (suppliedConfig.trackingInfo.utm_campaign && typeof suppliedConfig.trackingInfo.utm_campaign === 'string') {
                resultingConfig.trackingInfo.utm_campaign = suppliedConfig.trackingInfo.utm_campaign;
            }
            if (suppliedConfig.trackingInfo.utm_medium && typeof suppliedConfig.trackingInfo.utm_medium === 'string') {
                resultingConfig.trackingInfo.utm_medium = suppliedConfig.trackingInfo.utm_medium;
            }
            if (suppliedConfig.trackingInfo.utm_term && typeof suppliedConfig.trackingInfo.utm_term === 'string') {
                resultingConfig.trackingInfo.utm_term = suppliedConfig.trackingInfo.utm_term;
            }
            if (suppliedConfig.trackingInfo.utm_source && typeof suppliedConfig.trackingInfo.utm_source === 'string') {
                resultingConfig.trackingInfo.utm_source = suppliedConfig.trackingInfo.utm_source;
            }
            if (suppliedConfig.trackingInfo.eaid && typeof suppliedConfig.trackingInfo.eaid === 'string') {
                resultingConfig.trackingInfo.eaid = suppliedConfig.trackingInfo.eaid;
            }
        }

        if (Object.keys(resultingConfig).length < 1) {
            return null;
        }
        return resultingConfig;
    }

    private async initShopSettings(): Promise<void> {
        try {
            if (!window.OtShopSettings) {
                return Promise.resolve();
            }

            await window.OtShopSettings.init({
                baseUrl: import.meta.env.VITE_SHOP_SETTINGS_API_URL || 'https://custom.shop.openticket.tech',
                httpRead: axios.create(),
                shopId: this.shopId,
            });

            // Set custom style props
            if (window.OtShopSettings.static && window.OtShopSettings.static.style) {
                Style.publicSetStyle(window.OtShopSettings.static.style);
            }

            if (window.OtShopSettings.static && window.OtShopSettings.static.theme) {
                Style.setTheme(window.OtShopSettings.static.theme as StyleTheme);
            }
        } catch (_) {
            /* Silent fail */
        }

        return Promise.resolve();
    }

    public async initClient(window: Window): Promise<void> {
        this.client = new CrossWindowClient(window, { type: 'popup', ...(this._config || {}) });

        // Set this listener before connecting to prevent race conditioning the shopData result
        this.client.onShopData((shopData) => {
            // Delay setting the tracking information until we know what Google tag we need the tracking information for
            if ('setTrackingInformation' in this.client && typeof this.client.setTrackingInformation === 'function' && this._config) {
                this.client.setTrackingInformation(this._config.trackingInfo, shopData.google_tag || undefined);
            }
        });

        await this.client.connecting;

        this._resolveInitialized();

        this.client.onCartData((data: PartialCartData) => {
            this._dom.shakeButton();

            if (!this.cartDataChanged(data)) {
                return;
            }

            let ticketCount = 0;
            if (Object.keys(data.tickets).length) {
                Object.values(data.tickets).forEach((ticket) => {
                    ticketCount += ticket.count;
                });
            }

            this._widgetStatus.numberOfTickets = ticketCount;
            this._widgetStatus.totalPriceCents = data.checkout_details.total_price;
            this.updateWidgetStatus();

            if (this._cartTicketsUpdatedListener) {
                const ticketData = Object.fromEntries(Object.entries(data.tickets)
                    .map(
                        ([ k, v ]) => [ k, { selected: v.count } ],
                    ));

                this._cartTicketsUpdatedListener(ticketData);
            }

            if (this._orderTotalUpdatedListener) {
                this._orderTotalUpdatedListener(
                    this._localization.formatters.currency(data.checkout_details.total_price, this.client.localization.currency),
                );
            }

            if (this._rawOrderTotalUpdatedListener) {
                this._rawOrderTotalUpdatedListener(data.checkout_details.total_price, this.client.localization.currency);
            }
        });

        this.client.onCustomMessage((data: { [key: string]: unknown }) => {
            if (data && data.type === 'closePopup') {
                this.close();
            }
        });
    }

    private cartDataChanged(data: PartialCartData): boolean {
        const stringified: string = JSON.stringify(data);

        const equal: boolean = this._lastCartData === stringified;

        this._lastCartData = stringified;

        return equal;
    }

    private updateWidgetStatus(): void {
        const statusCached = `${this._widgetStatus.status}_${this._widgetStatus.numberOfTickets?.toString(10)}_${this._widgetStatus.totalPriceCents?.toString(10)}`;
        if (this._widgetStatus.previousStatus !== statusCached) {
            if (this._widgetStatusListener) {
                this._widgetStatusListener(
                    this._widgetStatus.status,
                    this._widgetStatus.numberOfTickets,
                    this._widgetStatus.totalPriceCents,
                );
            }
            this._dom.updateStatus(
                this._widgetStatus.status,
                this._widgetStatus.numberOfTickets,
                this._widgetStatus.totalPriceCents,
            );
            this._widgetStatus.previousStatus = statusCached;
        }
    }

    public widgetToggled(cb: WidgetToggledCallback): void {
        this._widgetToggledListener = cb;
    }

    public widgetStatus(cb: WidgetStatusCallback): void {
        this._widgetStatusListener = cb;
    }

    public cartTicketsUpdated(cb: CartCallback): void {
        this._cartTicketsUpdatedListener = cb;
    }

    public orderTotalUpdated(cb: OrderTotalCallback): void {
        this._orderTotalUpdatedListener = cb;
    }

    public rawOrderTotalUpdated(cb: OrderTotalRawCallback): void {
        this._rawOrderTotalUpdatedListener = cb;
    }

    public async addTicket(guid: string): Promise<void> {
        if (!guid) {
            throw Error('The ticket guid is required');
        }

        await this.initialized;
        this.client.sendMessage({
            action: 'add',
            guid,
            type: 'ticket',
        });
    }

    public async removeTicket(guid: string): Promise<void> {
        if (!guid) {
            throw Error('The ticket guid is required');
        }

        await this.initialized;
        this.client.sendMessage({
            action: 'remove',
            guid,
            type: 'ticket',
        });
    }

    public async addCoupon(code: string): Promise<void> {
        if (!code) {
            throw Error('The coupon code is required');
        }

        await this.initialized;

        this.client.sendMessage({
            action: 'add',
            code,
            type: 'coupon',
        });
    }

    public async removeCoupon(code: string): Promise<void> {
        if (!code) {
            throw Error('The coupon code is required');
        }

        await this.initialized;

        this.client.sendMessage({
            action: 'remove',
            code,
            type: 'coupon',
        });
    }

    public open(): void {
        this._dom.openWidget();
    }

    public close(): void {
        this._dom.closeWidget();
    }

    public toggle(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

}
